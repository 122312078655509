@font-face {
  font-family: 'Merriweather';
  src: url(./assets/fonts/Merriweather-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'MontserratLight';
  src: url(./assets/fonts/Montserrat-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'MontserratSemiBold';
  src: url(./assets/fonts/Montserrat-SemiBold.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #525765;
  background-color: #373a42;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#cvName {
  font-family: 'Merriweather', serif;
  font-weight: 600;
  font-size: 2rem;
}

#cvHeading1 {
  font-family: 'MontserratSemiBold', sans-serif;
  font-weight: 600;
  font-size: 1rem;
}

#cvHeading2 {
  font-family: 'MontserratSemiBold', sans-serif;
  font-weight: 200;
  font-size: 0.75rem;
}

#cvText {
  font-family: 'MontserratLight', sans-serif;
  font-weight: 500;
  font-size: 0.6rem;
  color: black;
}

#projectImg {
  object-fit: cover;
  object-position: center center;
  width: 15rem;
  height: 13rem;
}
